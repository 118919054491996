import { createAction, createReducer } from "@reduxjs/toolkit";

const AgencyList = createAction("AgencyList");
const AgencyCount = createAction("AgencyCount");
const ActiveClaims = createAction("ActiveClaims");
const ApprovedClaims = createAction("ApprovedClaims");
const CanceledClaims = createAction("CanceledClaims");
const RegionsList = createAction("RegionsList");
const ExchangeRateList = createAction("ExchangeRateList");
const Role = createAction("Role");
const Fullname = createAction("Fullname");
const AllocationsList = createAction("AllocationsList");
const FinancialAllocation = createAction("FinancialAllocation");
const PointBreakdown = createAction("PointBreakdown");
const HomepageData = createAction("HomepageData");
const PaystackWalletBalance = createAction("PaystackWalletBalance");
const PointsData = createAction("PointsData");
const MarketallocationData = createAction("MarketallocationData");
const TransactionhistoryData = createAction("TransactionhistoryData");
const ClaimsData = createAction("ClaimsData");
const FlownticketSumsData = createAction("FlownticketSumsData");
const PaystackTotalDisbursed = createAction("PaystackTotalDisbursed");
const PaystackTransactionHistory = createAction("PaystackTransactionHistory");
const DirectorList = createAction("DirectorList");
const PaystackRecipientList = createAction("PaystackRecipientList");
const SeerbitPocketData = createAction("SeerbitPocketData");
const SeerbitTransactionHistory = createAction("SeerbitTransactionHistory");
const Privileges = createAction("Privileges");
const AvailableProviders = createAction("AvailableProviders");
const AvailablePaymentoptions = createAction("AvailablePaymentoptions");
const ListofAgencies = createAction("ListofAgencies");

const initialState = {
  agency: [],
  agencycount: 0,
  activeclaims: 0,
  approvedclaims: 0,
  canceledclaims: 0,
  regionlist: [],
  exchangerate: [],
  role: "",
  privileges: [],
  fullname: "",
  allocations: [],
  financialdata: [],
  allocatedcountries: [],
  pointbreakdown: {},
  homepagedata: {},
  paystackwalletdata: 0,
  paystackrecipientlist: [],
  pointsdata: [],
  marketallocationdata: [],
  transactionhistorydata: [],
  claimsdata: [],
  flownticketsum: [],
  paystacktotaldisbursedata: 0,
  paystacktransacthistorydata: [],
  directordata: [],
  pocketdata: [],
  seerbittransactionHistory: [],
  providerlist: [],
  paymentoptionlist: [],
  agencylist: [],
};

export const responseReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(AgencyList, (state, action) => {
      state.agency = action.payload;
    })

    .addCase(AgencyCount, (state, action) => {
      state.agencycount = action.payload;
    })

    .addCase(DirectorList, (state, action) => {
      state.directordata = action.payload;
    })

    .addCase(ActiveClaims, (state, action) => {
      state.activeclaims = action.payload;
    })

    .addCase(ApprovedClaims, (state, action) => {
      state.approvedclaims = action.payload;
    })

    .addCase(CanceledClaims, (state, action) => {
      state.canceledclaims = action.payload;
    })

    .addCase(RegionsList, (state, action) => {
      state.regionlist = action.payload;
    })

    .addCase(ExchangeRateList, (state, action) => {
      state.exchangerate = action.payload;
    })

    .addCase(Role, (state, action) => {
      state.role = action.payload;
    })

    .addCase(Privileges, (state, action) => {
      state.privileges = action.payload;
    })

    .addCase(Fullname, (state, action) => {
      state.fullname = action.payload;
    })

    .addCase(AllocationsList, (state, action) => {
      state.allocations = action.payload;
    })

    .addCase(FinancialAllocation, (state, action) => {
      state.financialdata = action.payload;
    })

    .addCase(PointBreakdown, (state, action) => {
      state.pointbreakdown = action.payload;
    })

    .addCase(HomepageData, (state, action) => {
      state.homepagedata = action.payload;
    })

    .addCase(PaystackWalletBalance, (state, action) => {
      state.paystackwalletdata = action.payload;
    })

    .addCase(PaystackRecipientList, (state, action) => {
      state.paystackrecipientlist = action.payload;
    })

    .addCase(PaystackTotalDisbursed, (state, action) => {
      state.paystacktotaldisbursedata = action.payload;
    })

    .addCase(PaystackTransactionHistory, (state, action) => {
      state.paystacktransacthistorydata = action.payload;
    })

    .addCase(PointsData, (state, action) => {
      state.pointsdata = action.payload;
    })

    .addCase(MarketallocationData, (state, action) => {
      state.marketallocationdata = action.payload;
    })

    .addCase(TransactionhistoryData, (state, action) => {
      state.transactionhistorydata = action.payload;
    })

    .addCase(ClaimsData, (state, action) => {
      state.claimsdata = action.payload;
    })

    .addCase(FlownticketSumsData, (state, action) => {
      state.flownticketsum = action.payload;
    })

    .addCase(SeerbitPocketData, (state, action) => {
      state.pocketdata = action.payload;
    })

    .addCase(SeerbitTransactionHistory, (state, action) => {
      state.seerbittransactionHistory = action.payload;
    })

    .addCase(AvailableProviders, (state, action) => {
      state.providerlist = action.payload;
    })

    .addCase(AvailablePaymentoptions, (state, action) => {
      state.paymentoptionlist = action.payload;
    })

    .addCase(ListofAgencies, (state, action) => {
      state.agencylist = action.payload;
    });
});
