import React, { useState } from "react";
import ButtonPagenate from "./ButtonPagenate/ButtonPagenate";

const RuleEngine = () => {
  const [activeTab, setActiveTab] = useState(1);
  const handleClick = (props) => {
    setActiveTab(props);
  };

  const handleSubmitForm = () => {};
  return (
    <div className="flex-1 flex-col flex gap-3">
      <div className="w-full p-2 flex justify-start items-center gap-1 bg-[white] shadow-sm">
        <div
          onClick={(e) => handleClick(1)}
          className={`px-2 py-1 font-normal text-[14px] cursor-pointer ${
            activeTab === 1
              ? "text-l py-1 font-medium bg-[#a4a4a4] text-[white]"
              : "bg-[white] text-[#333]}"
          }`}
        >
          PROMO RULES
        </div>

        <div
          onClick={(e) => handleClick(2)}
          className={`px-2 py-1 font-normal text-[14px] cursor-pointer shadow h-full ${
            activeTab === 2
              ? "text-l font-medium bg-[#a4a4a4] text-[white]"
              : "bg-[white] text-[#333]}"
          }`}
        >
          EXCHANGE RATE RULES
        </div>
      </div>
      <form
        className="flex-1 bg-[white] p-4 flex flex-col gap-3"
        onSubmit={handleSubmitForm}
      >
        <div className="flex justify-center items-center">
          <h2 className="text-l font-bold mb-2">CREATE PROMO RULES</h2>
        </div>

        {/* <div className="flex-1 flex-wrap flex gap-2 items-center justify-between">
          <div className="flex-1 flex flex-col gap-1 items-start">
            <label className="text-[12px] font-medium">Payment Mode : </label>
            <Select
              options={paymentmodeOptions}
              value={selectPaymentMode}
              onChange={handleModeChange}
              className="w-full min-w-[180px] text-[12px] rounded-sm grow"
              placeholder="Select Payment Mode"
            />
          </div>
        </div> */}

        {/* {selectPaymentMode?.label === "ONLINE" && (
          <div className="flex-1 flex-wrap flex gap-2 items-center justify-between">
            <div className="flex-1 flex flex-col gap-1 items-start">
              <label className="text-[12px] font-medium">
                Payment Provider :{" "}
              </label>
              <Select
                options={paymentproviderOptions}
                value={selectProviderMode}
                onChange={handleProviderChange}
                className="w-full min-w-[180px] text-[12px] rounded-sm grow"
                placeholder="Select Payment Provider"
              />
            </div>
          </div>
        )} */}

        <div className="flex-1 flex-wrap flex gap-2 items-center justify-between">
          <div className="flex-1 flex flex-col gap-1 items-start">
            <label className="text-[12px] font-medium">Payment Region : </label>
            {/* <Select
              options={regionOptions}
              value={selectedRegion}
              onChange={handleRegionChange}
              className="w-full min-w-[180px] text-[12px] rounded-sm grow"
              placeholder="Select Region"
            /> */}
          </div>
        </div>

        <div className="flex-1 flex-wrap flex gap-2 items-center justify-between">
          <div className="flex-1 flex flex-col gap-1 items-start">
            <label className="text-[12px] font-medium">Agency: </label>
            {/* <Select
              options={filteredAgencies.map((agency) => ({
                value: agency.ID,
                label: `${agency.agency_name} ➡️ ${agency.first_name} ${agency.last_name}`,
              }))}
              value={selectAgency}
              onChange={handleAgency}
              className="w-full min-w-[180px] text-[12px] rounded-sm grow"
              placeholder="Select Agency"
            /> */}
          </div>
        </div>

        <input
          type="submit"
          className="btn btn-primary mt-4 text-[14px] font-semibold text-[white] py-2 rounded-sm bg-[#0743077d] hover:bg-[#074307f7] cursor-pointer"
          value={"SAVE CHANGES"}
        />
      </form>

      {/* <div className="flex-1 bg-[white] p-3 flex flex-col gap-2">
        <table className="min-w-full border-collapse">
          <thead>
            <tr>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                #SN
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Payment Mode
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Payment Provider
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Region
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Agency
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {paymentoptionlist
              .slice(pagesVisited, pagesVisited + itemsPerpage)
              .map((tdata, index) => (
                <tr className="body-tab-row cursor-pointer" key={tdata.id}>
                  <td
                    data-label="SN"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {index + 1 + pagesVisited}
                  </td>

                  <td
                    data-label="Payment Mode"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {tdata.payment_mode}
                  </td>

                  <td
                    data-label="Payment Provider"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {
                      providerlist.find(
                        (items) => items.id === tdata.paymentprovider_id
                      )?.payment_provider
                    }
                  </td>

                  <td
                    data-label="Region"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {
                      regionlist.find((items) => items.ID === tdata.region_id)
                        ?.name
                    }
                  </td>

                  <td
                    data-label="Agency"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {
                      agency.find((items) => items.ID === tdata.agency_id)
                        ?.agency_name
                    }
                  </td>
                  <td
                    data-label="Action"
                    className="text-[11px] flex items-center justify-center whitespace-normal break-words border px-2 py-1"
                  >
                    <AiFillDelete
                      onClick={() => handleDelete(tdata.id)}
                      id="butn-action"
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="bnw-table-pgnate-contain">
          <ButtonPagenate
            updatedPageNum={newPagenum}
            pageCount={pageCount}
            pageNumber={pageNumber}
            previousLabel="Prev"
            nextLabel="Next"
          />
        </div>
      </div> */}
    </div>
  );
};

export default RuleEngine;
