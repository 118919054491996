import React, { useState, useEffect } from "react";
import ButtonPagenate from "../Component/ButtonPagenate/ButtonPagenate";
// import Toggle from '../Component/Togglebutton/Togglebutton'
import "./page.css";
// import DirectorTableJson from './directortable'
import axios from "axios";
import { useCookies } from "react-cookie";
import SLoader from "../Component/Loading/SigninLoader";
import Error from "../Component/Error";
import { AiFillDelete } from "react-icons/ai";
import regionJson from "./Claims/regionJson";
import { useSelector } from "react-redux";
import { API } from "../server";
import { CSVLink } from "react-csv";
import { GiWorld } from "react-icons/gi";
import { FiUpload } from "react-icons/fi";
// import Regionsjson from '../Component/Cards/regionsjson'

const Regions = ({
  setloading,
  closeOverlay,
  setadd,
  setExpire,
  nameSearch,
}) => {
  const criteria = useSelector((state) => state.searchdata.searchtext);
  const { regionlist } = useSelector((state) => state.datareponse);

  // const [cookies] = useCookies(["Token"]);
  const [incomingdata, setIncomingdata] = useState([]);
  // const [treatConfirm, setTreatConfirm] = useState(true)
  const [pageNumber, setPageNumber] = useState(0);
  // const [loading, setLoading] = useState(true);
  const [confirmresponse, setConfirmresponse] = useState(0);
  const [exportpro, setExportpro] = useState(false);

  const itemsPerpage = 15; //Number of items to be displayed per page
  const pageCount = Math.ceil(incomingdata.length / itemsPerpage);
  const pagesVisited = pageNumber * itemsPerpage;

  useEffect(() => {
    setIncomingdata(regionlist);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionlist]);

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const handleAddButtn = () => {
    setadd();
  };

  const handleClose = () => {
    setConfirmresponse(0);
    closeOverlay();
  };

  var newdataset = incomingdata.filter((filteredItem) => {
    if (criteria === "" && nameSearch === "") {
      return filteredItem;
    } else if (criteria !== "" && nameSearch === "") {
      return filteredItem;
    } else if (criteria === "" && nameSearch !== "") {
      return filteredItem;
    } else if (criteria !== "" && nameSearch !== "") {
      if (criteria === "Region") {
        if (
          filteredItem.name.toLowerCase().includes(nameSearch.toLowerCase())
        ) {
          return filteredItem;
        }
      } else {
        return filteredItem;
      }
    }
  });

  const csvHeaders = [
    { label: "Id", key: "ID" },
    { label: "Firstname", key: "first_name" },
    { label: "Lastname", key: "last_name" },
    { label: "Agency Name", key: "agency_name" },
    { label: "Phone Number", key: "phone" },
    { label: "Email Address", key: "email" },
    { label: "Region", key: "region_code" },
  ];

  const handleHover = () => {
    setExportpro(!exportpro);
  };

  const handleExportCSV = () => {};

  return (
    <div className="bnw-director-container flex flex-col gap-2">
      <div className="w-full flex flex-col gap-1">
        <div className="w-full justify-between flex bg-[#e50000] p-1">
          <div className="relative flex justify-center items-center">
            <span className="flex items-center px-2 py-1 text-center cursor-pointer text-[#fff] text-[12px] font-[600]">
              REGIONS
            </span>

            <GiWorld size={25} color="#fff" />
          </div>

          <CSVLink data={incomingdata} headers={csvHeaders}>
            <div
              className={`relative border px-2 py-1 cursor-pointer rounded-md flex justify-center items-center ${
                exportpro ? "bg-[white]" : "bg-[#e50000]"
              }`}
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
              onClick={handleExportCSV}
            >
              <FiUpload size={18} color={exportpro ? "#e50000" : "#fff"} />
              <span
                className={`px-2 py-1 cursor-pointer ${
                  exportpro ? "text-[#e50000]" : "text-[#fff]"
                } text-[12px] font-[600]`}
              >
                Export
              </span>
            </div>
          </CSVLink>
        </div>

        <table className="bnw-claims-table">
          <thead id="bnw-claims-table-head">
            <tr>
              {/* <td>Remove</td> */}
              <th scope="col" className="text-[13px]">
                Id{" "}
              </th>
              <th scope="col" className="text-[13px]">
                Region Code
              </th>
              <th scope="col" className="text-[13px]">
                Region
              </th>
              <th scope="col" className="text-[13px]">
                Creation Date
              </th>
            </tr>
          </thead>
          <tbody>
            {newdataset
              .filter(
                (items) =>
                  items.region_code !== "CWA_Finance" &&
                  items.region_code !== "CWA_Admin" &&
                  items.region_code !== "DEV"
              )
              .reverse()
              .slice(pagesVisited, pagesVisited + itemsPerpage)
              .map((item, index) => (
                <tr key={index}>
                  {/* <td><AiFillDelete id='icon-id'/></td> */}
                  <td data-label="REG. ID" className="text-[11px]">
                    {index + 1}
                  </td>
                  <td data-label="REG. CODE" className="text-[11px]">
                    {item.region_code}
                  </td>
                  <td data-label="REGION" className="text-[11px]">
                    {item.name}
                  </td>
                  <td data-label="CRETD. DATE" className="text-[11px]">
                    {new Date(
                      item.CreatedAt || item.created_at
                    ).toLocaleString()}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="bnw-table-pgnate-contain">
        <button id="addbitn" onClick={handleAddButtn} className="text-[13px]">
          Add
        </button>
        <ButtonPagenate
          updatedPageNum={newPagenum}
          pageCount={pageCount}
          pageNumber={pageNumber}
          previousLabel="Prev"
          nextLabel="Next"
        />
      </div>
    </div>
  );
};

export default Regions;
