import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { buttonClickReducer } from "./Reducers/buttonClick";

import { responseReducer } from "./Reducers/dataResponse";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { thunk } from "redux-thunk";
import { searchReducer } from "./Reducers/searchFilter";
import { pocketReducer } from "./Reducers/pocketDetails";
import { treatClaimReducer } from "./Reducers/treatClaims";
import { roleprevilegeReducer } from "./Reducers/roles";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [""],
};

const rootReducer = combineReducers({
  buttonclick: buttonClickReducer,
  datareponse: responseReducer,
  searchdata: searchReducer,
  pocketData: pocketReducer,
  treatclaim: treatClaimReducer,
  rolesData: roleprevilegeReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const Store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), thunk],
});

export const persistor = persistStore(Store);

export const resetStore = async () => {
  await persistor.purge(); // Clears the persisted state
};
