import React, { useState } from "react";
import "./style.css";
import axios from "axios";
import { CgCloseO } from "react-icons/cg";
import Error from "./Error";
import LoadVeriy from "./Loading/LoadVeriy";
import { useCookies } from "react-cookie";
import { API, NodeServer_API } from "../server";
import { toast } from "react-toastify";
import { Store } from "../Redux/store";
import { useSelector } from "react-redux";
import { loadingData, toggle_Status } from "../Redux/Actions/buttonClick";
import { getAllAgencies, getAllDirectors } from "../Redux/Actions/dataResponse";

const Confirms = () => {
  const { toggleStatus } = useSelector((state) => state.buttonclick);

  const [cookies] = useCookies(["Token"]);
  const [confirmresponse, setConfirmresponse] = useState(0);
  const [confirmload, setConfirmload] = useState(false);
  const [expiration, setExpiration] = useState(false);

  const closeConfirm = () => {
    Store.dispatch(
      toggle_Status({
        id: 0,
        status: 0,
        page: "",
      })
    );
  };

  const handleActivate = async () => {
    Store.dispatch(
      loadingData({
        status: true,
        text: "Updating Users ...",
        type: "spin",
      })
    );
    try {
      var config = {
        method: toggleStatus.page === "agencies" ? "POST" : "PUT",
        url:
          toggleStatus.page === "agencies"
            ? `${API}/admin/updateAgent`
            : `${NodeServer_API}/directors/edit/${toggleStatus.id}`,
        data: {
          status: toggleStatus.status === 0 ? 1 : 0,
        },
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          if (toggleStatus.page === "agencies") {
            Store.dispatch(getAllAgencies(response.data.data));
          } else if (toggleStatus.page === "director") {
            Store.dispatch(getAllDirectors(response.data.data));
          }
          Store.dispatch(
            loadingData({
              status: false,
              text: "",
              type: "spin",
            })
          );
          toast.success(response.data.message);
          handleClose();
        }
      });
    } catch (e) {
      if (e.message.includes("401")) {
        toast.error(e?.res?.data);
        setExpiration(true);
      } else {
        setExpiration(false);
        Store.dispatch(
          loadingData({
            status: false,
            text: "",
            type: "spin",
          })
        );
      }
    }
  };

  const handleCloseOverlay = () => {
    setConfirmresponse(0);
    closeConfirm();
  };

  // const handleDecline = async () => {
  //   setConfirmload(true);
  //   try {
  //     var config = {
  //       method: "POST",
  //       url: `${API}/admin/updateAgent`,
  //       data: {
  //         id: toggleStatus.id,
  //       },
  //       headers: {
  //         Authorization: "Bearer " + cookies.Token,
  //       },
  //     };

  //     await axios(config).then((response) => {
  //       if (response.data.success === true) {
  //         setConfirmload(false);
  //         toast.success(response.data.message);
  //         handleClose();
  //       }
  //     });
  //   } catch (e) {
  //     if (e.message.includes("401")) {
  //       setConfirmresponse(3);
  //       setExpiration(true);
  //     } else {
  //       setConfirmload(false);
  //       setExpiration(false);
  //       toast.error(e?.res?.data);
  //     }
  //   }
  // };

  const handleClose = () => {
    setConfirmresponse(0);
    closeConfirm();
  };

  return (
    <div className="bnw-confirm-pg">
      {confirmload ? (
        <LoadVeriy type="spin" text="Processing..." />
      ) : (
        <div className="w-[50%] min-w-[280px] h-[250px] bg-[white] p-3 flex items-center flex-col gap-3">
          <div className="w-full flex items-center justify-end">
            <CgCloseO
              onClick={closeConfirm}
              size={28}
              className="cursor-pointer"
            />
          </div>

          <span className="text-[15px] font-[800] underline underline-offset-4">
            GRANT LOGIN ACCESS?
          </span>

          <span className="text-[14px]">
            {toggleStatus.page === "agencies" &&
              toggleStatus.status === 1 &&
              "Are you sure you want to deactivate this Client ?"}
            {toggleStatus.page === "agencies" &&
              toggleStatus.status === 0 &&
              "Are you sure you want to activate this Client ?"}
            {toggleStatus.page === "director" &&
              toggleStatus.status === 1 &&
              "Are you sure you want to deactivate this User ?"}
            {toggleStatus.page === "director" &&
              toggleStatus.status === 0 &&
              "Are you sure you want to activate this User ?"}
          </span>

          <div className="flex justify-around items-center w-full mt-5">
            <div className="relative bg-[blue] h-fit flex items-center justify-center">
              <button
                onClick={handleActivate}
                className="bg-[green] text-[white] flex justify-center items-center text-[12px] font-[500] w-[50px] p-2 cursor-pointer"
              >
                YES
              </button>
            </div>

            <div className="relative h-fit flex items-center justify-center">
              <button
                onClick={handleCloseOverlay}
                className="bg-[red] text-[white] flex justify-center items-center text-[12px] font-[500] w-[50px] p-2 cursor-pointer"
              >
                NO
              </button>
            </div>
          </div>
        </div>
      )}
      {confirmresponse === 2 ? (
        <Error
          closeOverlay={handleClose}
          message={"Claims Declined"}
          isTimeout={false}
          icon="2"
        />
      ) : confirmresponse === 3 ? (
        expiration ? (
          <Error
            message={"Session Expired Re-Login"}
            isTimeout={true}
            icon="3"
          />
        ) : (
          <Error
            closeOverlay={handleClose}
            message={"Connection Error"}
            isTimeout={false}
            icon="2"
          />
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default Confirms;
