import React, { useState, useEffect } from "react";
import Select from "react-select";
import { paymentmodelist } from "../utils/paymentmode";
import { useSelector } from "react-redux";
import { Store } from "../Redux/store";
import { loadingData } from "../Redux/Actions/buttonClick";
import axios from "axios";
import { NodeServer_API } from "../server";
import { toast } from "react-toastify";
import { getPaymentOptions } from "../Redux/Actions/dataResponse";
import ButtonPagenate from "./ButtonPagenate/ButtonPagenate";
import { AiFillDelete } from "react-icons/ai";
import { useCookies } from "react-cookie";

const Payments = () => {
  const [cookies] = useCookies(["Token"]);
  const [selectPaymentMode, setSelectPaymentMode] = useState(null);
  const [selectProviderMode, setSelectProviderMode] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectAgency, setSelectAgency] = useState(null);
  const [filteredAgencies, setFilteredAgencies] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const { regionlist, agency, providerlist, paymentoptionlist } = useSelector(
    (state) => state.datareponse
  );

  const { privileges } = useSelector((state) => state.rolesData);
  const privilegeOptions = privileges?.map((privilege) => ({
    value: privilege.id,
    label: privilege.privilege_name,
  }));

  useEffect(() => {
    Store.dispatch(
      loadingData({
        status: false,
        text: "",
        type: "spin",
      })
    );
  }, []);

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const itemsPerpage = 10; // Number of items to be displayed per page
  const pagesVisited = pageNumber * itemsPerpage;
  const pageCount = Math.ceil(
    (paymentoptionlist === null ? 0 : paymentoptionlist.length) / itemsPerpage
  );

  const paymentmodeOptions = paymentmodelist?.map((mode) => ({
    value: mode.id,
    label: mode.paymentmode_name,
  }));

  const paymentproviderOptions = providerlist?.map((provider) => ({
    value: provider.id,
    label: provider.payment_provider,
  }));

  const regionOptions = regionlist?.map((region) => ({
    value: region.ID,
    label: region.name,
    regionCode: region.region_code,
  }));

  const handleModeChange = (mode) => {
    setSelectPaymentMode(mode);
    // Reset provider selection if payment mode changes
    if (mode.label !== "ONLINE") {
      setSelectProviderMode(null);
    }
  };

  const handleRegionChange = (region) => {
    setSelectedRegion(region);

    // Filter agencies based on selected region's region_code
    const agenciesForRegion = agency.filter(
      (agencyItem) => agencyItem.region_code === region.regionCode
    );

    setFilteredAgencies(agenciesForRegion);
    setSelectAgency(null); // Reset agency selection if region changes
  };

  const handleProviderChange = (provider) => {
    setSelectProviderMode(provider);
  };

  const handleAgency = (agency) => {
    setSelectAgency(agency);
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    if (!selectedRegion) {
      toast.error("Payment region is required.");
      return;
    }

    Store.dispatch(
      loadingData({
        status: true,
        text: "Creating Payment Option...",
        type: "spin",
      })
    );

    const payload = {
      payment_mode: selectPaymentMode.label,
      paymentprovider_id: selectProviderMode?.value,
      region_id: selectedRegion.value,
      agency_id: selectAgency?.value || 0, // Optional, so it can be null
    };

    const config = {
      method: "POST",
      url: `${NodeServer_API}/payment`,
      data: payload,
      headers: {
        Authorization: "Bearer " + cookies.Token,
      },
    };

    try {
      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(
            loadingData({
              status: false,
              text: "",
              type: "spin",
            })
          );

          toast.success(response.data.message);
          Store.dispatch(getPaymentOptions(response.data.data));
        }
      });
    } catch (error) {
      console.error(
        "Error saving payment options:",
        error.response.data.message
      );
      toast.error(error.response.data.message);
      Store.dispatch(
        loadingData({
          status: false,
          text: "",
          type: "spin",
        })
      );
    }
  };

  const handleNoDelete = () => {};

  // Open performance appraisal page to fetch the record of user with the particular ID if it exists in the db
  const handleDelete = async (props) => {
    Store.dispatch(
      loadingData({
        status: true,
        text: "Deleting Payment Option...",
        type: "spin",
      })
    );

    try {
      var config = {
        method: "DELETE",
        url: `${NodeServer_API}/payment/delete/${props}`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        // console.log(response.data.data);
        if (response.data.success === true) {
          toast.success(response.data.message);

          Store.dispatch(getPaymentOptions(response.data.data));

          Store.dispatch(
            loadingData({
              status: false,
              text: "",
              type: "spin",
            })
          );
        }
      });
    } catch (e) {
      Store.dispatch(
        loadingData({
          status: false,
          text: "",
          type: "spin",
        })
      );
      toast.error(e.response.data.message);
    }
  };

  return (
    <div className="flex-1 flex-col flex gap-3">
      <form
        className="flex-1 bg-[white] p-4 flex flex-col gap-3"
        onSubmit={handleSubmitForm}
      >
        <div className="flex justify-center items-center">
          <h2 className="text-l font-bold mb-2">CREATE PAYMENTS OPTIONS</h2>
        </div>

        <div className="flex-1 flex-wrap flex gap-2 items-center justify-between">
          <div className="flex-1 flex flex-col gap-1 items-start">
            <label className="text-[12px] font-medium">Payment Mode : </label>
            <Select
              options={paymentmodeOptions}
              value={selectPaymentMode}
              onChange={handleModeChange}
              className="w-full min-w-[180px] text-[12px] rounded-sm grow"
              placeholder="Select Payment Mode"
            />
          </div>
        </div>

        {selectPaymentMode?.label === "ONLINE" && (
          <div className="flex-1 flex-wrap flex gap-2 items-center justify-between">
            <div className="flex-1 flex flex-col gap-1 items-start">
              <label className="text-[12px] font-medium">
                Payment Provider :{" "}
              </label>
              <Select
                options={paymentproviderOptions}
                value={selectProviderMode}
                onChange={handleProviderChange}
                className="w-full min-w-[180px] text-[12px] rounded-sm grow"
                placeholder="Select Payment Provider"
              />
            </div>
          </div>
        )}

        <div className="flex-1 flex-wrap flex gap-2 items-center justify-between">
          <div className="flex-1 flex flex-col gap-1 items-start">
            <label className="text-[12px] font-medium">Payment Region : </label>
            <Select
              options={regionOptions}
              value={selectedRegion}
              onChange={handleRegionChange}
              className="w-full min-w-[180px] text-[12px] rounded-sm grow"
              placeholder="Select Region"
            />
          </div>
        </div>

        <div className="flex-1 flex-wrap flex gap-2 items-center justify-between">
          <div className="flex-1 flex flex-col gap-1 items-start">
            <label className="text-[12px] font-medium">Agency: </label>
            <Select
              options={filteredAgencies.map((agency) => ({
                value: agency.id,
                label: `${agency.agency_name} ➡️ ${agency.first_name} ${agency.last_name}`,
              }))}
              value={selectAgency}
              onChange={handleAgency}
              className="w-full min-w-[180px] text-[12px] rounded-sm grow"
              placeholder="Select Agency"
            />
          </div>
        </div>

        <input
          disabled={
            privilegeOptions.find(
              (privilege) => privilege.label === "Create User"
            )?.label === "Create Payment"
              ? false
              : true
          }
          type="submit"
          className="btn btn-primary mt-4 text-[14px] font-semibold text-[white] py-2 rounded-sm bg-[#0743077d] hover:bg-[#074307f7] cursor-pointer"
          value={"SAVE CHANGES"}
        />
      </form>

      <div className="flex-1 bg-[white] p-3 flex flex-col gap-2">
        <table className="min-w-full border-collapse">
          <thead>
            <tr>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                #SN
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Payment Mode
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Payment Provider
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Region
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Agency
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {paymentoptionlist
              .slice(pagesVisited, pagesVisited + itemsPerpage)
              .map((tdata, index) => (
                <tr className="body-tab-row cursor-pointer" key={tdata.id}>
                  <td
                    data-label="SN"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {index + 1 + pagesVisited}
                  </td>

                  <td
                    data-label="Payment Mode"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {tdata.payment_mode}
                  </td>

                  <td
                    data-label="Payment Provider"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {
                      providerlist.find(
                        (items) => items.id === tdata.paymentprovider_id
                      )?.payment_provider
                    }
                  </td>

                  <td
                    data-label="Region"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {
                      regionlist.find((items) => items.ID === tdata.region_id)
                        ?.name
                    }
                  </td>

                  <td
                    data-label="Agency"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {
                      agency.find((items) => items.ID === tdata.agency_id)
                        ?.agency_name
                    }
                  </td>
                  <td
                    data-label="Action"
                    className="text-[11px] flex items-center justify-center whitespace-normal break-words border px-2 py-1"
                  >
                    <AiFillDelete
                      onClick={() =>
                        privilegeOptions.find(
                          (privilege) => privilege.label === "Delete Payment"
                        )?.label === "Delete Blacklist"
                          ? handleDelete(tdata.id)
                          : handleNoDelete()
                      }
                      id="butn-action"
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="bnw-table-pgnate-contain">
          <ButtonPagenate
            updatedPageNum={newPagenum}
            pageCount={pageCount}
            pageNumber={pageNumber}
            previousLabel="Prev"
            nextLabel="Next"
          />
        </div>
      </div>
    </div>
  );
};

export default Payments;
