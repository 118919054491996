import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import AgencyClaim from "../agencyclaimJson";
import ButtonPagenate from "../../Component/ButtonPagenate/ButtonPagenate";
import { useCookies } from "react-cookie";
import axios from "axios";
import currencyUnit from "./currencyUnit";
import { useSelector } from "react-redux";
import { API } from "../../server";
import { CSVLink } from "react-csv";
import { FiUpload } from "react-icons/fi";
import { GiPayMoney } from "react-icons/gi";
import claimsFunction from "../../Component/Scripts/claimsfunction.jsx";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import formatDateTime from "../../Component/DateSelector/Datefunction.jsx";

const Claim = ({ setloading }) => {
  const [cookies] = useCookies(["Token"]);
  const [pageNumber, setPageNumber] = useState(0);
  const [incomingdata, setIncomingdata] = useState([]);

  const [currencyrate, setCurrencyrate] = useState({
    XOF: 0,
  });
  const [exportpro, setExportpro] = useState(false);
  const { exchangerate, claimsdata } = useSelector(
    (state) => state.datareponse
  );

  // console.log(claimsdata);
  const { criteria, agencyname } = useSelector(
    (state) => state.searchdata.searchtext
  );

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    CURRENCYAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CURRENCYAPI = () => {
    let config = {
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/latest?access_key=${process.env.REACT_APP_CURRENCY_API}`,
    };

    // console.log(config);

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        setCurrencyrate(response?.data?.rates);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  let allocationData = claimsFunction(
    claimsdata,
    currencyUnit,
    exchangerate.data
  );

  // console.log(claimsdata)
  // console.log(allocationData);

  const itemsPerpage = 15; //Number of items to be displayed per page
  const pageCount = Math.ceil(incomingdata?.length / itemsPerpage);
  const pagesVisited = pageNumber * itemsPerpage;

  var newdataset = allocationData?.filter((filteredItem) => {
    if (criteria === "" && agencyname === "") {
      return filteredItem;
    } else if (criteria !== "" && agencyname === "") {
      return filteredItem;
    } else if (criteria === "" && agencyname !== "") {
      return filteredItem;
    } else if (criteria !== "" && agencyname !== "") {
      if (criteria === "Region") {
        if (
          filteredItem?.Country?.toLowerCase().includes(
            agencyname?.toLowerCase()
          )
        ) {
          return filteredItem;
        }
      } else if (criteria === "Agency Name") {
        if (
          filteredItem.agency_name
            ?.toLowerCase()
            .includes(agencyname.toLowerCase())
        ) {
          return filteredItem;
        }
      } else {
        return filteredItem;
      }
    }
  });

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const CumTicketEquv_USD = newdataset
    .reduce((accumulator, currentValue) => {
      const equivalentSumUSD =
        (currentValue.points * currentValue.local_per_ticket) /
          currentValue.local_per_usd !==
        Infinity
          ? Math.ceil(
              ((currentValue.points * currentValue.local_per_ticket) /
                currentValue.local_per_usd) *
                10
            ) / 10
          : 0;
      return accumulator + equivalentSumUSD;
    }, 0)
    .toFixed(1);

  const Pointsum = newdataset.reduce(
    (accumulator, currentValue) => accumulator + currentValue.points,
    0
  );

  const statusObj = {
    1: "Approved",
    2: "Pending",
    3: "Declined",
    4: "",
  };

  const csvHeaders = [
    { label: "Id", key: "ID" },
    { label: "Firstname", key: "first_name" },
    { label: "Lastname", key: "last_name" },
    { label: "Agency Name", key: "agency_name" },
    { label: "Phone Number", key: "phone" },
    { label: "Email Address", key: "email" },
    { label: "Region", key: "region_code" },
  ];

  const handleHover = () => {
    setExportpro(!exportpro);
  };

  const handleExportCSV = () => {};

  return (
    <div className="bnw-body-container-tab flex flex-col gap-1 ">
      <div className="w-full flex flex-col gap-1">
        <div className="w-full justify-between flex bg-[#e50000] p-1">
          <div className="relative flex justify-center items-center">
            <span className="flex items-center px-2 py-1 text-center cursor-pointer text-[#fff] text-[12px] font-[600]">
              CLAIMS
            </span>

            <GiPayMoney size={25} color="#fff" />
          </div>

          <CSVLink data={allocationData} headers={csvHeaders}>
            <div
              className={`relative border px-2 py-1 cursor-pointer rounded-md flex justify-center items-center ${
                exportpro ? "bg-[white]" : "bg-[#e50000]"
              }`}
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
              onClick={handleExportCSV}
            >
              <FiUpload size={18} color={exportpro ? "#e50000" : "#fff"} />
              <span
                className={`px-2 py-1 cursor-pointer ${
                  exportpro ? "text-[#e50000]" : "text-[#fff]"
                } text-[12px] font-[600]`}
              >
                Export
              </span>
            </div>
          </CSVLink>
        </div>

        <table>
          <thead>
            <tr>
              {/* <th scope="col">Id</th> */}
              <th scope="col" className="text-[13px]">
                SN
              </th>
              <th scope="col" className="text-[13px]">
                Agency Name
              </th>
              <th scope="col" className="text-[13px]">
                Country
              </th>
              <th scope="col" className="text-[13px]">
                Points
              </th>
              <th scope="col" className="text-[13px]">
                Tkt/Local
              </th>
              <th scope="col" className="text-[13px]">
                USD/Local
              </th>
              <th scope="col" className="text-[13px]">
                Point Equv.
              </th>
              <th scope="col" className="text-[13px]">
                USD Equv.
              </th>
              <th scope="col" className="text-[11px]">
                Payment Mode
              </th>
              <th scope="col" className="text-[13px]">
                Date
              </th>
              <th scope="col" className="text-[13px]">
                Status
              </th>
            </tr>
          </thead>

          <tbody>
            {newdataset
              .map((tdata, index) => (
                <Tooltip
                  TransitionComponent={Zoom}
                  title={tdata.last_name + " " + tdata.first_name}
                  followCursor
                >
                  <tr className="body-tab-row" key={index}>
                    <td data-label="SN" className="text-[11px]">
                      {index + 1}
                    </td>
                    <td data-label="Agency Name" className="text-[11px]">
                      {tdata.agency_name}
                    </td>

                    <td data-label="Country" className="text-[11px]">
                      {
                        currencyUnit.find(
                          (currencyunit) =>
                            currencyunit.region_code === tdata.region_code
                        ).name
                      }
                    </td>

                    <td data-label="Points" className="text-[11px]">
                      {tdata.points}
                    </td>

                    <td data-label="Tkt/Local Rate" className="text-[11px]">
                      <NumberFormat
                        value={tdata.local_per_ticket}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={tdata.currency + " "}
                      />
                    </td>

                    <td data-label="USD/Local Rate" className="text-[11px]">
                      <NumberFormat
                        value={tdata.local_per_usd}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={tdata.currency + " "}
                      />
                    </td>

                    <td data-label="Point Equivalent" className="text-[11px]">
                      <NumberFormat
                        value={tdata.points_equivalent}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={tdata.currency + " "}
                      />
                    </td>

                    <td data-label="USD Equv." className="text-[11px]">
                      <NumberFormat
                        value={
                          tdata.amountPerLocalCurrency === 0
                            ? "rate not set"
                            : parseFloat(
                                Math.ceil(
                                  ((tdata.points * tdata.local_per_ticket) /
                                    tdata.local_per_usd) *
                                    10
                                ) / 10
                              ).toFixed(1)
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={tdata.amountPerLocalCurrency === 0 ? "" : " $"}
                      />
                    </td>

                    <td data-label="Payment Mode" className="text-[11px]">
                      {tdata.payment_info.payment_mode}
                    </td>

                    <td data-label="Date" className="text-[11px]">
                      {formatDateTime(tdata.updated_at)}
                    </td>

                    <td data-label="Status">
                      {tdata.claim_status !== "" ? (
                        <div
                          id={
                            tdata.claim_status === 0
                              ? "bnw-tab-status_1"
                              : tdata.claim_status === 1
                              ? "bnw-tab-status"
                              : "bnw-tab-status_2"
                          }
                          className="text-[11px]"
                        >
                          {tdata.claim_status === 0
                            ? statusObj[2]
                            : tdata.claim_status === 1
                            ? statusObj[1]
                            : tdata.claim_status === 2
                            ? statusObj[3]
                            : statusObj[4]}
                        </div>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                </Tooltip>
              ))
              .slice(pagesVisited, pagesVisited + itemsPerpage)}

            <tr className="body-tab-roww text-[13px]">
              <td data-label="SN">Total</td>
              <td data-label="Agency Name"></td>
              <td data-label="Country"></td>
              <td data-label="Points">{Pointsum}</td>
              <td data-label="Tkt/Local"></td>
              <td data-label="USD/Local"></td>
              <td data-label="Point Equv."></td>
              <td data-label="USD Equv.">
                <NumberFormat
                  value={CumTicketEquv_USD}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                />
              </td>
              <td data-label="Payment Mode"></td>
              <td data-label="Date"></td>
              <td data-label="Status"></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="bnw-table-pgnate-contain">
        <ButtonPagenate
          updatedPageNum={newPagenum}
          pageCount={pageCount}
          pageNumber={pageNumber}
          previousLabel="Prev"
          nextLabel="Next"
        />
      </div>
    </div>
  );
};

export default Claim;
