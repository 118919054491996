import React, { useState, useEffect } from "react";
import Singleselect from "../Component/Singleselect/Singleselectt";
import "./promorate.css";
import "./table.css";
import axios from "axios";
import { useCookies } from "react-cookie";
import ButtonPagenate from "../Component/ButtonPagenate/ButtonPagenate";
import { useSelector } from "react-redux";
import { Store } from "../Redux/store";
import { getAllExchangerate } from "../Redux/Actions/dataResponse";
import { toast } from "react-toastify";
import Regionsjson from "../Component/Cards/regionsjson";

const Exchangerate = ({ setloading, closeOverlay }) => {
  const { exchangerate } = useSelector((state) => state.datareponse);
  // console.log(exchangerate);

  const [cookies] = useCookies(["Token"]);
  const API = "https://admin.sabrecwa.com/backend";
  const [regionsjson, setRegionsjson] = useState([]);
  const [currentRate, setCurrentRate] = useState([]);
  const [amount, setAmount] = useState({
    ticketperlocal: "",
    localperusd: "",
  });
  const [country, setCountry] = useState("");
  const [pageNumber, setPageNumber] = useState(0);

  const handleSelected = (incoming) => {
    // setcountry(incoming)
    setCountry(incoming);
  };

  const itemsPerpage = 15; //Number of items to be displayed per page
  const pageCount = Math.ceil(currentRate?.length / itemsPerpage);
  const pagesVisited = pageNumber * itemsPerpage;

  // console.log(currentRate)

  useEffect(() => {
    setRegions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentRate(exchangerate.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchangerate]);

  const setRegions = async () => {
    try {
      var config = {
        method: "GET",
        url: `${API}/regions`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          setRegionsjson(response.data.data);
        }
      });
    } catch (e) {
      if (e.message.includes("401") || e.message.includes("404")) {
        // // console.log(`Error `)
      } else {
        // setConfirmresponse(3)
        // setExpire()
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAmount({ ...amount, [name]: value.replace(/\D/g, "") });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const objt = {
      amountPerDollar: parseInt(amount.ticketperlocal),
      amountPerLocalcurrency: parseInt(amount.localperusd),
      region_code: country,
    };

    if (
      amount.amountPerDollar !== "" ||
      amount.localperusd !== "" ||
      country !== ""
    ) {
      if (objt.amountPerDollar > 0 && objt.amountPerLocalcurrency > 0) {
        setloading({
          statuscode: 1,
          message: "Updating Record",
          type: 1,
        });
        try {
          var config = {
            method: "POST",
            url: `${API}/setExchangeRate`,
            headers: {
              Authorization: "Bearer " + cookies.Token,
            },
            data: objt,
          };

          await axios(config).then((response) => {
            if (response.data.success === true) {
              // console.log(response.data)
              Store.dispatch(getAllExchangerate(response.data));
              toast.success("Exchange Rate Set Successfully!");

              setloading({
                statuscode: 0,
                message: "",
                type: "",
              });
            }
          });
        } catch (e) {
          if (e.response.status === 401) {
            setloading({
              // Expired Session
              statuscode: 3,
              message: "Session Expired Re-Login",
              type: 3,
            });
          } else {
            setloading({
              statuscode: 2,
              message: e.message,
              type: 2,
            });
          }
        }
      } else {
        /****
         * Value should be greater than zero
         */
      }
    }
  };

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  return (
    <div className="bnw-exrt-main-container">
      <div className="bnw-promopg-container">
        <form className="bnw-promopg gap-2" onSubmit={handleSubmit}>
          <p className="text-[17px] font-[700]">Create Exchange Rate</p>
          <div className="bnw-promopg-field-contain">
            <div className="bnw-promopg-field-1">
              <span>Amount/Ticket :</span>
            </div>
            <div className="bnw-promopg-field-2">
              <input
                placeholder="1 Ticket → Local Cur."
                type="text"
                onChange={handleChange}
                value={amount.ticketperlocal}
                id="amout-per-dollar"
                className="text-[13px]"
                name="ticketperlocal"
              />
            </div>
          </div>

          <div className="bnw-promopg-field-contain">
            <div className="bnw-promopg-field-1">
              <span>Currency/USD :</span>
            </div>
            <div className="bnw-promopg-field-2">
              <input
                placeholder="1 USD → Local Cur."
                type="text"
                onChange={handleChange}
                value={amount.localperusd}
                id="amout-per-dollar"
                className="text-[13px]"
                name="localperusd"
              />
            </div>
          </div>

          <div className="bnw-promopg-field-contain">
            <div className="bnw-promopg-field-1">
              <span>Country</span>
            </div>
            <div className="bnw-promopg-field-2">
              <Singleselect
                selectedSocVal={handleSelected}
                socialjs={regionsjson}
                placeholder="Region"
              />
            </div>
          </div>

          <div className="bnw-promopg-field-contain">
            <div className="bnw-promopg-field-1" />
            <div className="bnw-promopg-field-2">
              <input className="bnw-promopg-submits" type="submit" />
            </div>
          </div>
        </form>

        <div className="bnw-promopg-exch-table">
          <table>
            <thead>
              <tr className="text-[14px]">
                <th scope="col" className="text-[13px]">
                  Id
                </th>
                <th scope="col" className="text-[13px]">
                  Amount/Tkt
                </th>
                <th scope="col" className="text-[13px]">
                  Currency/USD
                </th>
                <th scope="col" className="text-[13px]">
                  Region Code
                </th>
              </tr>
            </thead>
            <tbody>
              {currentRate
                ?.slice(pagesVisited, pagesVisited + itemsPerpage)
                .map((tdata) => (
                  <tr className="body-tab-row" key={tdata.ID}>
                    <td data-label="Id" className="text-[11px]">
                      {tdata.ID}
                    </td>
                    <td data-label="Amount/Tkt" className="text-[11px]">
                      {tdata.amountPerDollar}
                    </td>
                    <td data-label="Currency/USD" className="text-[11px]">
                      {tdata.amountPerLocalCurrency}
                    </td>
                    <td data-label="Region Code" className="text-[11px]">
                      {
                        Regionsjson.find(
                          (element) => element.value === tdata.region_code
                        )?.label
                      }
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="bnw-table-pgnate-contain">
            <ButtonPagenate
              updatedPageNum={newPagenum}
              pageCount={pageCount}
              pageNumber={pageNumber}
              previousLabel="Prev"
              nextLabel="Next"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Exchangerate;
